function showChangelog() {
  document.body.className = '-changelog';
  changelog.className = 'changelog -show';
  setTimeout(function() {
    changelog.className = 'changelog -show -animate';
    changelogcontent.className = 'content -show';
  }, 0);
}

function hideChangelog() {
  changelogcontent.className = 'content';
  changelog.className = 'changelog -show';
  setTimeout(function() {
    changelog.className = 'changelog';
  }, 200);
  document.body.className = '';
}

changelogbutton.addEventListener('click', showChangelog);
changelogfooter.addEventListener('click', showChangelog);
// document.querySelectorAll('.buyversion')[0].addEventListener('click', showChangelog);
// document.querySelectorAll('.buyversion')[1].addEventListener('click', showChangelog);
changelogclose.addEventListener('click', hideChangelog);
changelogcontent.addEventListener('click', function(e) {
  e.stopPropagation();
});
changelog.addEventListener('click', hideChangelog);

if (window.location.href.indexOf('ref=dootech') == -1) {
  setTimeout(function() {
    dootech.className = 'dootech -show';
  }, 5000);
}

dootech.querySelector('.close').addEventListener('click', function(e) {
  dootech.className = 'dootech';
  e.stopPropagation();
});

dootech.addEventListener('click', function() {
  window.open('https://www.dootech.com?ref=gabar', '_blank');
  dootech.className = 'dootech';
});

if (window.location.href.indexOf('ref=annotate') > -1) {
  ph.style.display = 'block';
}

function index(el) {
  if (!el) return -1;
  var i = 0;
  do {
    if (el.nodeType === 1) i++;
    el = el.previousSibling;
  } while (el)
  return i;
}

var Testimonials = {

  reviews: document.querySelectorAll('.review'),

  init: function() {
    if (this.reviews.length % 2 == 0) {
      console.error('Number of testimonials shouldn\'t be even!')
    }
    this.center = Math.ceil(this.reviews.length / 2);
    this.bindEvents();
    this.startLoop();
  },

  startLoop: function() {
    this.interval = setInterval(this.moveForward, 10000);
  },

  moveForward: function() {
    testimonials.className = 'wrap -animate';
    if (window.innerWidth > 1000) {
      testimonials.style.marginLeft = -850 + 'px';
    } else {
      testimonials.style.marginLeft = -750 + 'px';
    }
    testimonials.querySelector('.review:nth-child(' + Testimonials.center + ')').className = 'review';
    testimonials.querySelector('.review:nth-child(' + (Testimonials.center+1) + ')').className = 'review active';
    setTimeout(function() {
      var fragment = document.createDocumentFragment();
      fragment.appendChild(testimonials.querySelector('.review:nth-child(1)'));
      testimonials.appendChild(fragment);
      testimonials.className = 'wrap';
      testimonials.style.marginLeft = 0;
    }, 300);
  },

  moveBackward: function() {
    testimonials.className = 'wrap -animate';
    if (window.innerWidth > 1000) {
      testimonials.style.marginLeft = 850 + 'px';
    } else {
      testimonials.style.marginLeft = 750 + 'px';
    }
    testimonials.querySelector('.review:nth-child(' + Testimonials.center + ')').className = 'review';
    testimonials.querySelector('.review:nth-child(' + (Testimonials.center-1) + ')').className = 'review active';
    setTimeout(function() {
      var fragment = document.createDocumentFragment();
      fragment.appendChild(testimonials.querySelector('.review:nth-child(' + Testimonials.reviews.length + ')'));
      testimonials.insertBefore(fragment, testimonials.querySelector('.review:nth-child(1)'));
      testimonials.className = 'wrap';
      testimonials.style.marginLeft = 0;
    }, 300);
  },

  bindEvents() {
    for (var i = 0; i < this.reviews.length; i++) {
      this.reviews[i].addEventListener('click', function(e) {
        if (index(e.target) > Testimonials.center) {
          clearInterval(Testimonials.interval);
          Testimonials.moveForward();
        } else if (index(e.target) < Testimonials.center) {
          clearInterval(Testimonials.interval);
          Testimonials.moveBackward();
        }
        Testimonials.startLoop();
      });
    }
  }

};
Testimonials.init();

// smallplay.addEventListener('click', function() {
//   showPlayer();
// });

// play.addEventListener('click', function() {
//   showPlayer();
// });

// buyplay.addEventListener('click', function() {
//   showPlayer();
// });

function showPlayer() {
  video.className = 'video show';
  setTimeout(function() {
    video.className = 'video show animate';
  }, 0);
  setTimeout(function() {
    player.play();
  }, 200);
}

player.addEventListener('click', function(e) {
  e.stopPropagation();
});

function hidePlayer() {
  player.pause();
  video.className = 'video show';
  setTimeout(function() {
    video.className = 'video';
  }, 200);
}

video.addEventListener('click', hidePlayer);

document.addEventListener('keydown', function(e) {
  if (video.className === 'video show animate') {
    if (e.which === 27) {
      hidePlayer();
    } else if (e.which === 32) {
      e.preventDefault();
      if (player.paused) {
        player.play();
      } else {
        player.pause();
      }
    }
  }
});

var contacts = document.querySelectorAll('._mailto');

for (var i = 0; i < contacts.length; i++) {
  contacts[i].href = decodeURIComponent('mai'+'lto%3Ahell'+'o%4'+'0doote'+'ch.com');
}

// trialcta.addEventListener('click', function() {
//   showTrial();
// });

// buytrialcta.addEventListener('click', function() {
//   showTrial();
// });

function showTrial() {
  trial.className = 'trial show';
  setTimeout(function() {
    trial.className = 'trial show animate';
  }, 0);
}

trial.querySelector('.content').addEventListener('click', function(e) {
  e.stopPropagation();
});

function hideTrial() {
  trial.className = 'trial show';
  setTimeout(function() {
    trial.className = 'trial';
  }, 200);
}

trial.addEventListener('click', hideTrial);
// trialclose.addEventListener('click', hideTrial);
trial.querySelector('.action').addEventListener('click', hideTrial);